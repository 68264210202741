import React from 'react';
import ReactDom from 'react-dom';
import ReactHabitat from 'react-habitat';
import { ChakraProvider } from "@chakra-ui/react";
import theme from 'frontend/theme';
import { CSSReset } from 'frontend/chakra';


let globalsInjected = false;


/**
 * Chakra DOM Factory for rendering our react components in a ChakraProvider wrapper
 */
export default class ChakraDomFactory {

    /**
     * Initialize the factory with the theme for Chakra.
     */
    constructor(theme = null) {
        this.theme = theme;
    }

    /**
     * Inject the module into the dom wrapped in a ChakraProvider
     * @param {*} module - The component to inject
     * @param {object} props  - The component props
     * @param {node} target - The node to inject to
     */
    inject(module, props = {}, target) {
        if (target) {
            const chakraProviderProps = {
                resetCSS: false,
            };
            if (this.theme !== null) {
                chakraProviderProps.theme = this.theme;
            }

            // The component that react-habitat is rendering wrapped in a `div.chakra-scope` for style rules to match
            const componentWithScope = React.createElement(
                'div',
                {
                    className: 'chakra-scope',
                },
                React.createElement(module, props)
            );

            let componentsToInsert = [
                componentWithScope,
            ];

            // Only need to reset the css the first time
            if (globalsInjected === false) {
                // Chakra has some base CSS "reset" rules that it applies to the page
                // use our custom version and force it to only apply elements found under a
                // `.chakra-scope` class element.
                const cssResetComponent = React.createElement(CSSReset);

                componentsToInsert.push(cssResetComponent);
                globalsInjected = true;
            }

            const chakraWrappedComponent = React.createElement(
                ChakraProvider,
                { ...chakraProviderProps },
                ...componentsToInsert
            );

            // Render the component wrapped in all the needed providers
            ReactDom.render(
                chakraWrappedComponent,
                target
            );
        }
    }

    /**
     * Dispose of any react instances for a node
     * @param {node} target - The node to tear down
     */
    dispose(target) {
        if (target) {
            ReactDom.unmountComponentAtNode(target);
        }
    }
}


class ReactRegistry extends ReactHabitat.Bootstrapper {
    constructor() {
        super();

        const builder = new ReactHabitat.ContainerBuilder({
            replaceDisabled: false
        });

        builder.factory = new ChakraDomFactory(theme);

        // Register all React snippets here
        builder.registerAsync(() => import("frontend/social/components").then(mod => mod.FacebookLoginButton)).as('FacebookLoginButton');
        builder.registerAsync(() => import("anvil_social_src/components").then(mod => mod.TwitterFollowButton)).as('TwitterFollowButton');
        builder.registerAsync(() => import("frontend/social/components").then(mod => mod.AppleSSOButton)).as('AppleSSOButton');
        builder.registerAsync(() => import("anvil_social_src/components").then(mod => mod.ShareButtons)).as('ShareButtons');
        builder.registerAsync(() => import('content_src/components').then(mod => mod.Byline)).as('Byline');
        builder.registerAsync(() => import('content_src/snippets').then(mod => mod.RssFeedReader)).as('RssFeedReader');
        builder.registerAsync(() => import('content_src/snippets').then(mod => mod.ImageGallery)).as('ImageGallery');
        builder.registerAsync(() => import("react-dfp").then(mod => mod.AdSlot)).as('AdSlot');
        builder.registerAsync(() => import('doubleclick/js/DynamicAdSlot/DynamicAdSlot')).as('DynamicAdSlot');
        builder.registerAsync(() => import('business_directory/search/BusinessSearchAutocomplete')).as('BusinessSearchAutocomplete');
        builder.registerAsync(() => import('content_src/components').then(mod => mod.Paywall)).as('Paywall');
        builder.registerAsync(() => import('video_gallery/VideoGallery')).as('VideoGallery');
        builder.registerAsync(() => import('video_gallery/VideoPlayer')).as('VideoPlayer');
        builder.registerAsync(() => import('frontend/order_tunnel/routers/OrderTunnel')).as('OrderTunnel');
        builder.registerAsync(() => import('autopay/AutopayManager')).as('AutopayManager');

        this.setContainer(builder.build());
    }
}


// make sure all future elements that match the selector get tracked.
const mutationObserver = new MutationObserver((mutations) => {
    mutations
        .filter((mutation) => mutation.type === 'childList')
        .forEach((mutation) => {
            Array.from(mutation.addedNodes)
                .filter((node) => node.nodeType === Node.ELEMENT_NODE)
                .forEach((el) => {
                    if (el.matches('[data-component]') || el.querySelectorAll('[data-component]').length > 0) {
                        reactRegistry.update(el);
                    }
                })
        });
});

let reactRegistry;
window.addEventListener('DOMContentLoaded', () => {
    mutationObserver.observe(document.body, {
        subtree: true,
        childList: true,
    });
    reactRegistry = new ReactRegistry();
});


